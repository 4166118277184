export default defineNuxtRouteMiddleware((to) => {
  const { fbAuth } = useFirebase();
  fbAuth.onAuthStateChanged((user: any) => {
    if (user) {
      return navigateTo(to.path);
    } else {
      return navigateTo('/auth/login');
    }
  });
});
